/**
 * Banner Styles
 */

#page-banner {

    background-color: #ebece3;
	position: relative;
	overflow: visible;
	z-index: 2;

	.inside {
		height: 100%;
		overflow: visible;
	}

	.banner-tall {
    	border-bottom: 20px solid #3c3c3c;
		position: relative;
		height: auto;

		@include breakpoint(tablet) {
			padding: 0 0 10px 0;
			height: 530px;
		}
	}

	#manufacture-logo {
		padding: 10px 20px 10px 0;
		display: none;
		float: left;
		position: relative;
		z-index: 10;

		@include breakpoint(mobile) {
			display: block;
		}
	}

	#browse-nav {
		margin: 26px 0;
		position: relative;
		z-index: 50;

		@include breakpoint(mobile) {
			float: right;
		}

		h1 {
			padding: 15px 0;
			font-size: 22px;
			font-weight: 400;
		}

		.dropdown-toggle {
			height: 57px;
			line-height: 26px;
			white-space: nowrap;
			display: block;
			padding: 15px 70px 15px 15px;
			border-radius: $input-border-radius;
			border: 1px solid $text-color;
			position: relative;

			&:after {
				background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' viewBox='0 0 24 24'><path fill='rgb(255,255,255)' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
				background-color: #3c3c3c;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				content: "";
				height: 100%;
				width: 55px;
			}
		}

		> ul {
			list-style: none;
			padding: 0;
			text-align: right;
			position: relative;
			margin: 0;
			z-index: 50;

			@include breakpoint(tablet) {
				margin: 0 0 0 20px;
			}

			> li > a {
				display: block;
				background: #dcddcd;
				color: $text-color;
				text-decoration: none;
			}

			> li {
				list-style: none;
				margin: 0;
				padding: 0;
			}
	
			> li h1 {
				width: 100%;
				float: none;
				text-transform: none;
				padding: 0;
			}
		}

		.dropdown-menu {
			box-shadow: rgba(0, 0, 0, 0.4) 0 20px 34px 0;
			background: #dcddcd;
			display: none;
			position: absolute;
			top: 105%;
			right: 0;
			// left: -1px;
			z-index: 1000;
			border: 1px solid $text-color;
			margin: 0;
			padding: 0;
			list-style: none;
			padding: 20px;
			text-align: left;
			width: 100%;
			
			@include breakpoint(tablet) {
				min-width: 500px;
				width: auto;
			}

			h1,h2,h3,h4,h5,h6 {
				color: $text-color;
				padding: 0;
				margin: 0;
			}

			// .link-container {
			// 	display: flex;
			// 	flex-direction: row;
			// 	flex-flow: row wrap;
			// 	align-items: center;
			// }

			.link {
				margin: 0;
				padding: 0;

				@include breakpoint(tablet) {
					float: left;
					width: 33.3%;
				}
			}

			.img-container {
				background-size: contain;
					background-repeat: no-repeat;
				background-position: 50% 50%;
				height: 100px;
				width: 100%;
			}

			a {
				text-decoration: none;
				background: #dcddcd;
				display: block;
				font-size: 1em;
				padding: 15px;
				text-align: center;
				color: $text-color;

				&:hover,
				&:focus {
					background-color: $brand-color-2;
					color: #fff;

					h1,h2,h3,h4,h5,h6 {
						color: #fff;
					}
				}
			}
		}

		.open > .dropdown-menu {
			display: block;
		}
	}
	
	#banner-image {
		background: url('#{$image-path}/power-wheels-diagram.png') no-repeat 100% 100%;
		position: absolute;
		z-index: 0;
		bottom: -70px;
		right: -20px;
		display: none;
		height: 100%;
		width: 100%;

		@include breakpoint(tablet) {
			display: block;
		}
	}

	#breadcrumbs {
		background: #3c3c3c;
		color: #fff;
		padding: 2px 0 2px 0;
		position: relative;
		overflow: hidden;
		font-size: .75em;
		clear: both;

		&:after {
			background-size: contain;
			position: absolute;
			height: 100%;
			width: 100%;
			content: "";
			left: 0;
			top: 0;

		}

		ul {
			position: relative;
			list-style: none;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			z-index: 1;
			padding: 0;
			margin: 0;
		}

		li {
			display: inline-block;
			white-space: nowrap;
			position: relative;
			line-height: 10px;
			padding: 0;
			margin: 0;
		}

		li + li {
			&:before {
				content: ' : ';
				position: relative;
				padding: 0 5px;
			}
		}

		a {
			color: #fff;
		}
	}

}