/**
 * Body Styles
 */

#page-body {

	position: relative;
	font-size: 100%;
	clear: both;

 	#page-content {
		@include clearfix();
		position: relative;

		&.wide .section-content {
			width: 100%;
			float: none;
			padding: 60px 0;

			.content-inside {
				padding: 0;
			}
		}

		.section-content {
			min-height: $content-height-min;
			position: relative;
			padding: 30px 0;
			z-index: 1;

			@include breakpoint(tablet) {
				width: $content-width;
				float: left;

				.content-inside {
					padding: 0 25px 0 0;
				}
			}
		}

		.section-heading {
			margin: 0 0 40px 0;
		}

		.page-heading,
		.page-title {
			color: $brand-color-1;
			font-size: 1.667em;
			text-shadow: 2px 2px 1px #c8cabd;
			line-height: 32px;
			padding: 0;
			margin: 0 0 40px 0;
			font-weight: 400;
			
			&.product-page-title  {
				margin-bottom: 0;
			}

			h1,h2,h3,h4,h5,h6 {
				padding: 0;
				margin: 0;
			}
		}
	}

/*
* ================================
* 	_Sidebar
* ================================
*/

	.section-sidebar {
		background: $sidebar-bg;
		position: relative;
		overflow: hidden;
		color: #fff;
		height: 100%;
		float: none;
		width: 100%;
		height: 100%;
		padding: 0;
		z-index: 1;

		a {
			color: #fff;
		}
		 
		.chosen-container,
		.chosen-container a {
			color: $text-color;
		}

		.sidebar-inside {
			padding: 20px;
		}

		@include breakpoint(tablet) {
		    width: $sidebar-width;
		    margin-top: 0;
		    float: left;
		}

		@media screen and (min-height: 800px) {
			position: -webkit-sticky;
			position: sticky;
			top: 0;
		}
	}

	.sidebar-bg {
		@include breakpoint(tablet) {
			content: "";			
			background: $sidebar-bg;
			position: absolute;
			top: 0;
			display: block;
			right: 0;
			height: 100%;
			width: 25%;
			z-index: 0;
		}
	}
	
}
