/**
 * Widget Styles
 */

/*
 * ================================
 * 	_Global
 * ================================
 */

	.widget {
		font-size: 1em;

 		& + .widget {
 			margin-top: 30px;
 		}

 		.widget-heading {
			position: relative;
			padding: 0 20% 10px 0;
			margin: 0 0 10px 0;
			border-bottom: 1px solid #4d4d4f;

			h1,h2,h3,h4,h5,h6 {
				font-family: $font-family-secondary;
				font-size: 1em;
				padding: 0;
				margin: 0;
			}
 		}

 		.posts-widget {
 			a:hover {
	 			text-decoration: none;
	 		}
		}
 	}

/*
 * ================================
 * 	_PostsWidget
 * ================================
 */

	.widget.widget_recent_entries,
	.widget.posts-widget {
		.post-article + .post-article {
			border-top: 1px solid darken($brand-secondary,10%);
			padding-top: 25px;
			margin-top: 25px;
		}

		.post-article .post-content {
			padding: 0;
			margin: 0;
		}

		.post-article .post-info{
			margin: 0 0 10px 0;
		}

		.post-article .post-info h1{
			margin: 0 0 5px 0;
			line-height: 1em;
			padding: 0;
		}

		.post-article .post-info .date{
			padding: 0;
			margin: 0;
		}

		.post-article a {
			display: block;

			&:hover {
				text-decoration: none;

				.post-content {
					text-decoration: underline;
				}
			}
		}
	}

/*
 * ================================
 * 	_NavigationWidget
 * ================================
 */

	.widget.widget_nav_menu,
	.widget.nav-widget {
		li {
			padding: 0;
			margin: 0;
		}

		li + li {
			padding-top: 15px;
		}
	}

/*
 * ================================
 * 	_CategoriesWidget
 * ================================
 */

	.widget.widget_categories {
		li {
			padding: 0;
			margin: 0;
		}

		li + li {
			padding-top: 15px;
		}
	}

/*
 * ================================
 * 	_SearchWidget
 * ================================
 */

 .widget.widget_product_search,
	.widget.widget_search {
		.search-label {
			padding: 0;
			margin: 0;
		}

		form {
			display: flex;
			justify-content: space-between;
		}

		input {
			height: 32px;
			width: auto;
		}

		input[type="text"] {
			max-width: 80%;
		}

		input[type="submit"] {
			padding: 5px 8px;
		}
	}


/*
 * ================================
 * 	_CartWidget
 * ================================
 */

	.widget.widget_shopping_cart {
		.widget_shopping_cart_content {
			p {
				margin: 0;
				padding: 0;
			}

			.button {
				@extend .btn.btn-default;
				font-weight: 400;
			}

			.total {
				border-top: 1px solid #4d4d4f;
				margin-top: 10px;
				padding-top: 10px;
				text-align: right;
			}

			.woocommerce-mini-cart  {
				padding: 10px 5px;
				max-height: 200px;
				overflow: auto;

				li + li {
					margin-top: 10px;
				}

				a {
					font-size: .8em;
				}

				a.remove {
					font-size: 22px;
					margin: 0 auto;
					line-height: 25px;
					height: 25px;
					width: 25px;
					text-align: center;
				}
			}

			.woocommerce-mini-cart__buttons {
				margin-top: 10px;
				text-align: center;
			}
		}
	}

