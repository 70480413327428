/**
 * Ecommerce Styles
 *
 */

#page-body {


    // Account page
    .woocommerce {
    	.addresses {
    		.title {
    			@include clearfix();

    			h3 {
    				float: left;
    			}

    			.edit {
    				float: right;
    			}
    		}
    	}

    	ol.commentlist.notes {
    		li.note {

    			p.meta {
    				font-weight: 700;
    				margin-bottom: 0;
    			}

    			.description {
    				p:last-child {
    					margin-bottom: 0;
    				}
    			}
    		}
    	}
    	ul.digital-downloads {
    		margin-left: 0;
    		padding-left: 0;

    		li {
    			list-style: none;
    			margin-left: 0;
    			padding-left: 0;

    			&:before {
    				content: "\e00a";
    			}

    			.count {
    				float: right;
    			}
    		}
    	}
    }

}