/**
 * Grid Styles
 *
 */

.row,
.form-row,
.inside {

	&:before,
	&:after {
		display: table;
		line-height: 0;
		content: "";
	}

	&:after {
		clear: both;
	}

	&[class*="span"] {
		margin-left: 20px;
		min-height: 1px;
		float: left;
	}

	& + .row,
	& + .form-row {
		margin-top: em(30px);
	}

	.row,
	.inside {
		max-width: none;
		min-width: 0;
		width: auto;
	}

	[class*="span"] {
		width: auto !important;
		min-height: 30px;
		padding-left: 0;
		display: block;
		margin-left: 0;
		float: none;

		@include box-sizing(border-box);

		@include breakpoint(mobile) {
			margin-left: 2.127659574468085%;
			width: 100%;
			float: left;
		}
	}

	[class*="span"] + [class*="span"] {
		margin-top: 40px;
		padding-left: 0;

		@include breakpoint(mobile) {
			margin-top: 0;
		}
	}

	[class*="span"]:first-child {
		float: none;

		@include breakpoint(mobile) {
			margin-left: 0;
			float:left;
		}
	}

	.span1 	{ width: 6.38298%; }
	.span2 	{ width: 14.89362%; }
	.span3 	{ width: 23.40426%; }
	.span4 	{ width: 31.91489%; }
	.span5 	{ width: 40.42553%; }
	.span6 	{ width: 48.93617%; }
	.span7 	{ width: 57.44681%; }
	.span8 	{ width: 65.95745%; }
	.span9 	{ width: 74.46809%; }
	.span10 { width: 82.97872%; }
	.span11	{ width: 91.48936%; }
	.span12	{ width: 100%; }

}
