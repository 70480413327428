/**
 * Main Stylesheet
 */


@import "components/variables";
@import "font-awesome/scss/font-awesome";
@import "mixins/mixins";

/*
 * ================================
 * 	_Components
 * ================================
 */

	@import "components/reset";
	@import "components/buttons";
	@import "components/forms";
	@import "components/grid";
	@import "components/print";
	@import "components/type";

/*
 * ================================
 * 	_Shared
 * ================================
 */

	@import "shared/global";
	@import "shared/header";
	@import "shared/navigation";
	@import "shared/body";
	@import "shared/footer";
	@import "shared/banner";

/*
 * ================================
 * 	_Layouts
 * ================================
 */

	@import "layout/sitemap";
	@import "layout/blog";
	@import "layout/home";
	@import "layout/widgets";
	
/*
 * ================================
 * 	_Ecommerce
 * ================================
 */
 
	@import "ecommerce/account";
	@import "ecommerce/archive";
	@import "ecommerce/cart";
	@import "ecommerce/checkout";
	@import "ecommerce/global";
	@import "ecommerce/single";

