/**
 * Button Styles
 */

input.btn,
a.btn,
.btn {

	background-color: $brand-default;
	border-color: darken($brand-default, 5%);
	display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
	cursor: pointer;
	color: #fff;
	transition: color .15s ease-in-out,
	background-color .15s ease-in-out,
	border-color .15s ease-in-out,
	box-shadow .15s ease-in-out;

	// @include box-shadow(0 1px 3px -1px rgba(0,0,0,.6));

	&:hover,
	&:focus {
		background-color: lighten($brand-default, 2.5%);
		box-shadow: 0 0 0 0.2rem rgba($brand-default,.5);
		text-decoration: none;
		color: #fff;
	}

	&.disabled,
	&[disabled] {
		text-shadow:0 1px 0 #f7f6f3;
		background-color: #bab9b9;
		border-color:#a8a8a8;
		font-weight:bold;
		box-shadow: none;
		cursor: default;
		top:0;
	}

	&.btn-sm {
		padding: 6px 12px;
		line-height: 1em;
		font-size: .8em;
	}

	&.btn-lg {
		padding: 20px 30px;
		line-height: 1em;
		font-size: 1em;
	}

	&.btn-block {
		display: block;
		width: 100%;

		& + .btn-block {
			margin-top: 5px;
		}
	}

	// Alternative btns
	&.btn-default {
		background-color: $brand-default;
		color: #fff;

		&:hover,
		&:active{
			background-color: lighten($brand-default, 2%);
		}
	}

	&.btn-primary {
		background-color: $brand-primary;
		color: #fff;

		&:hover,
		&:active{
			background-color: lighten($brand-primary, 2%);
			box-shadow: 0 0 0 0.2rem rgba($brand-primary,.5);
		}
	}

	&.btn-warning {
		background-color:$brand-warning;
		color: #525252;

		&:hover,
		&:active{
			background-color: lighten($brand-warning, 2%);
			box-shadow: 0 0 0 0.2rem rgba($brand-warning,.5);
		}
	}

	&.btn-cancel,
	&.btn-danger {
		background-color: $brand-danger;
		color: #525252;

		&:hover,
		&:active{
			background-color: lighten($brand-danger, 2%);
			box-shadow: 0 0 0 0.2rem rgba($brand-danger,.5);
		}
	}

	&.btn-save,
	&.btn-success {
		background-color: $brand-success;
		color: #525252;

		&:hover,
		&:active{
			background-color: lighten($brand-success, 2%);
			box-shadow: 0 0 0 0.2rem rgba($brand-success,.5);
		}
	}

	&.btn-info {
		background-color: $brand-info;
		color: #fff;

		&:hover,
		&:active{
			background-color: lighten($brand-info, 2%);
			box-shadow: 0 0 0 0.2rem rgba($brand-info,.5);
		}
	}

	&.btn-text {
		background: transparent;
		box-shadow: none;
		padding-right: 0;
		padding-left: 0;
		border: none;

		&:active {
			bottom: auto;
		}

		&:focus,
		&:hover {
			text-decoration: underline;
			box-shadow: none;
		}
	}

}