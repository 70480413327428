/**
 * Ecommerce Styles
 *
 */

#page-body .woocommerce {

	table.shop_table {
		overflow: hidden;
		border: none;

		a.remove {
			margin: 0 auto;
			line-height: 22px;
			height: 25px;
			width: 25px;
			font-size: 22px;
			text-align: center;
		}

		.product-remove,
		.product-thumbnail {
			text-align: center;
			padding: 6px 2px;
		}

		.cart_item {
			border-color: transparent;
		}

		img {
			width: 32px;
			box-shadow: none;
		}

		th {
			text-transform: uppercase;
			font-size: .8em;
		}

		th,
		td {
			vertical-align: middle;
			border: none;
		}

		td {
			background: #fff;
			border: 1px solid $brand-border;
		}

		p {
			padding: 0;
			margin: 0;
		}

		tr:nth-child(odd) {
			td {
				background-color:#fafafa;
			}
		}

		tr:hover td {
			background: lighten($brand-border, 15%);
		}

		tr {
			& + tr {
				margin-top: 50px;
				
				@include breakpoint(tablet) {
					margin-top: 0;
				}
			}
		}


		td.actions {
			.coupon {
				.input-text {
					float: left;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
					border: 1px solid darken( $brand-primary, 10% );
					padding: 6px 6px 5px;
					margin: 0 4px 0 0;
					outline: 0;
					line-height: 1;
					min-width: 125px;
				}
			}
		}

		input {
			margin: 0;
			vertical-align: middle;
		}

		.input-text {
			height: 38px;
		}

		button {
			@extend .btn;
			@extend .btn-primary;
		}
	}

	.wc-proceed-to-checkout {
		@include clearfix;
		padding: 1em 0;

		a.checkout-button {
			display: block;
			text-align: center;
			margin-bottom: 1em;
			font-size: 1.25em;
			padding: 1em;
			color: #fff;
			@extend .btn.btn-primary;
		}
	}

	.cart-collaterals {
		.shipping_calculator {
			.button {
				width: 100%;
				float: none;
				display: block;
			}

			.shipping-calculator-button {
				&:after {
					content: "\e019";
				}
			}
		}

		.cart_totals {
			p {
				padding: 0;
				margin: 0;

				small {
					color: $text-color;
					font-size: 0.83em;
				}
			}

			.woocommerce-shipping-calculator {
				margin-top: 20px;
			}

			.shipping-calculator-form {
				padding: 10px 0;

				p + p {
					margin-top: 5px;
				}
			}

			table.shop_table {
				border-collapse: separate;
				margin: 0 0 6px;
				padding: 0;
				border: 1px solid $brand-border;
				background: #fff;
		
				tr:first-child {
					th,
					td {
						border-top: 0;
					}
				}

				td,
				th {
					border-left: 0;
					border-right: 0;
					line-height: 1.5em;
				}

				th {
					border-right: 1px solid $brand-border;
					width: 40%;
					text-align: right;
					vertical-align: middle;
				}

				tr:hover td {
					background: lighten($brand-border, 15%);
				}

				small {
					color: $text-color;
				}

				select {
					width: 100%;
				}
			}

			.discount td {
				color: $text-color;
			}

			tr td,
			tr th {
				border-top: 1px solid #e8e4e3;
			}
		}
	}
	
	#respond input#submit.alt, 
	a.button.alt, 
	button.button.alt,
	input.button.alt {
		color: #fff;
		@extend .btn.btn-primary;
	}
	
}
