/**
 * Variables
 */

/*
 * ================================
 *	_Paths
 * ================================
 */

	$font-path: 	"../fonts";
	$vendor-path: 	"../vendor";
	$image-path: 	"../images";

/*
 * ================================
 *	_Sizes
 * ================================
 */

	// Screen widths
	$screen-xs-min: 480px;
	$screen-sm-min: 768px;
	$screen-md-min: 992px;
	$screen-lg-min: 1200px;
	$screen-min: 290px;
	$screen-max: 1200px;

	// Layout sizes
	$header-height: 60px;
	$header-height-mobile: 58px;
	$footer-height: 4px;
	$sidebar-width: 25%;
	$content-width: 75%;
	$content-height-min: 300px;
	$breadcrumbs-height: 26px;

	// Padding
	$padding-base-vertical: 5px;
	$padding-base-horizontal: 5px;

/*
 * ================================
 *	_Colors
 * ================================
 */

	$brand-primary		: #f4f5f7;
	$brand-secondary	: #ddd;
	$brand-color-1		: #d51b00;
	$brand-color-2		: #066a9d;
	$brand-color-3		: #f4f5f7;
	$brand-color-4		: #3c3c3c;

	$brand-default		: $brand-color-1;
	$brand-primary		: $brand-color-2;
	$brand-success		: #70b333;
	$brand-info			: #5bc0de;
	$brand-warning		: #f0ad4e;
	$brand-danger		: #a50303;
	$brand-border		: #ccc;

	$gray-base			: #000;
	$gray-darker		: lighten($gray-base, 13.5%); // #222
	$gray-dark			: lighten($gray-base, 20%);   // #333
	$gray				: lighten($gray-base, 33.5%); // #555
	$gray-light			: lighten($gray-base, 46.7%); // #777
	$gray-lighter		: lighten($gray-base, 93.5%); // #eee

	$background-color	: #fafafa;
	$header-bg			: #fafafa;
	$footer-bg			: #ffffff;
	$sidebar-bg			: #287ea9;

	// Text
	$text-color			: $gray-dark;
	$text-color-dark	: $gray-dark;
	$link-color			: #21759b;
	$link-hover-color	: #d54e21;

	$header-text-color	: $gray-dark;
	$footer-text-color	: $gray-dark;
	$footer-link-color	: $gray-dark;
	$footer-link-hover-color: $gray-dark;

/*
 * ================================
 *	_Forms
 * ================================
 */

	$input-bg:				#fefefe;
	$input-bg-active:		darken($input-bg, 2%);
	$input-bg-disabled:		$gray-lighter;

	$input-error: 			#a50303;
	$input-color:			#4d4d4d;
	$input-border:			#ccc;
	$input-border-active:	#337ae5;
	$input-border-radius:	1px;
	$input-height-base: 	15px;
	$input-color-placeholder: $gray-light;

/*
 * ================================
 *	_Fonts
 * ================================
 */

	// Sizes
	$font-size-base: 16px;
	$line-height-base: 22px;

	// Fonts
	$font-family-sans-serif		: "Noto Sans", "Helvetica Neue", helvetica, arial, sans-serif;
	$font-family-monospace		: Menlo, Monaco, Consolas, "Courier New", monospace;
	$font-family-base			: $font-family-sans-serif;
	$font-family-secondary		: $font-family-sans-serif;

/*
 * ================================
 *	_Other
 * ================================
 */

	 $fa-css-prefix: 'icon';
	 $iconCssPrefix: 'icon';

