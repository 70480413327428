/**
 * Homepage Styles
 */

#homepage {

    #page-body {
        ul.products {
            border-top: 4px solid #4d4d4f;
            border-bottom: 4px solid #4d4d4f;
            color: #fff;
            margin: 10px 0;
            padding: 20px 0;

            a {
                color: #fff;
            }

            .container {
                border: 3px solid #4d4d4f;
                background: $brand-color-2;
                overflow: hidden;
                padding: 0;

                &:hover,
                &:focus {
                    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 44px 0px;
                }
            }

            .img-container {
                border-bottom: 3px solid #4d4d4f;
            }

            .price {
                del,
                ins {
                    color: #fff;
                }
            }

            .add_to_cart_button {
                display: none;
            }

            .woocommerce-loop-product__title,
            .price {
                padding: 0 20px;
            }
        }

        .section-content .content-inside {
            padding: 10px 0;
        }
    }

    #page-heading {
        background: url("#{$image-path}/striped-bg.jpg") repeat-x;
        border-bottom: 4px solid #4d4d4f;
        padding-bottom: 30px;
        margin-bottom: 30px;
        width: 100%;

        h1 {
            background: $background-color;
            color: $brand-color-1;
            text-transform: uppercase;
            font-size: 1.667em;
            text-shadow: 2px 2px 1px #c8cabd;
            line-height: 32px;
			font-weight: 400;
            padding: 0;
            margin: 0;

            @include breakpoint(tablet) {
                width: 55%;
            }
        }
    }

    .product-container {
        width: 100%;
        clear: both;

        @include clearfix();

        & + .product-container {
            padding: 20px 0 0 0;
            margin: 20px 0 0 0;
        }

        .product-box {
            width: 100%;

            & + .product-box {
                margin-top: 40px;
            }

            @include breakpoint(mobile) {
                margin: 0 1.5%;
                float: left;
                width: 22%;

                & + .product-box {
                    margin-top: 0;
                }
            }

            @include breakpoint(tablet) {
                width: 22%;
            }
            
            a {
                background-color: $brand-color-2;
                display: block;
                border: 3px solid #4d4d4f;
                text-decoration: none;
                transition: all .3s ease;

                &:hover,
                &:focus {
                    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 24px 0px;
                    background-color: darken($brand-color-2,5%);
                }
            }

            .img-container {
                border-bottom: 3px solid #4d4d4f;
                width: 100%;
                height: 14.222em;
                text-align: center;
                position: relative;
                overflow: hidden;
            }

            .img-container img {
                width: 100%;
                visibility: hidden;
            }

            .img-container .img {
                background: #fff;
                height: 100%;
                width: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }

            .details {
                margin: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .details h1 {
                border: none;
                margin: 0;
                padding: 0;
                line-height: 1em;
                font-size: .85em;
                color: #fff;
                font-weight: 400;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .details h2 {
                color: #fff;
                font-size: 1em;
                padding-top: 10px;
                color: #fff;
                font-weight: 400;
            }
        }
    }

}
