/**
 * Header Styles
 */

#page-header {

	border-bottom: 6px solid $brand-color-2;
	background: $header-bg;
	padding: 5px 0 15px 0;
	font-size: 100%;
	z-index: 300;
	width: 100%;
	clear: both;

	@include breakpoint(tablet) {
		border-bottom: 6px solid #ebece3;
		padding: 20px 0;
	}

	.nav-button {
		line-height: ($header-height-mobile - 3);
		height: $header-height-mobile;
		width: $header-height-mobile;
		text-decoration: none;
		position: relative;
		text-align: center;
		text-indent: 3px;
		font-size: 54px;
		display: block;
		float: right;
		color: $text-color;
		max-width: 20%;
		margin-top: 18px;

		@include transition(all .3s ease);
		@include icon($fa-var-navicon);

		@include breakpoint(tablet) {
			display: none;
		}
	}

	.search-form {
		font-size: .9em;

		input[type="submit"] {
			padding: 1px 8px;
		}

		input[type="text"] {
			height: auto;
			display: inline-block;
			vertical-align: middle;
			padding: 2px 10px;
			height: 28px;
			width: auto;
		}
	}

	.helpful-links {
		padding: 25px 0 0 0;
		font-size: .9em;
		vertical-align: middle;
		text-align: center;
		display: none;
		clear: both;

		@include breakpoint(tablet) {
			padding: 0;
			text-align: right;
			max-width: 30%;
			display: block;
			clear: none;
			float: right;
		}

		p {
			line-height: 1;
			padding: 0;
			margin: 0;

			& + .search-form,
			& + p {
				padding-top: 3px;
			}
		}
	}

	.logo {
		float: left;
		line-height: 1em;
		max-width: 80%;
			
		@include breakpoint(tablet) {
			max-width: 70%;
		}

		a {
			text-decoration: none;
			display: block;
		}

		h1 {
			margin: 0;
			padding: 0;
			font-family: Impact, Anton, sans-serif;
			font-style: italic;
			font-size: 22px;
			color: #d51b00;
			text-transform: uppercase;
			letter-spacing: .03em;
			padding: 15px 0 0 0;
			
			@include breakpoint(mobile) {
				padding: 15px 0 0 0;
				font-size: 28px;
			}
			
			@include breakpoint(tablet) {
				padding: 0;
				font-size: 36px;
			}
		}

		h2 {
			margin: 10px 0 0;
			padding: 0;
			font-size: .95em;
			color: #4d4d4f;
			
			@include breakpoint(tablet) {
				display: block;
			}
		}
	}

}

#page-info {
	background: $sidebar-bg;
	color: #fff;
	width: 100%;
	font-size: .75em;
	padding: 3px 0;
	z-index: 500;
	display: none;
	@include clearfix();

	@include breakpoint(tablet) {
		display: block;
	}

	a {
	color: #fff;
	}

	.box {
		float: left;

		& + .box {
			float: right;
		}
	}

	.info {
		display: inline-block;
		padding: 0 10px 0;

		p {
			padding: 0;
			margin: 0;
		}
	}
}