/**
 * Print Styles
 *
 */

@include breakpoint(print) {

	* { background: transparent !important; color: #000 !important; box-shadow:none !important; text-shadow: none !important; filter:none !important; -ms-filter: none !important; }
	pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
	body, .inside{padding:0;margin:0;width:auto;min-width:auto;}
	a, a:visited { text-decoration: underline; }
	thead { display: table-header-group; }
	tr, img { page-break-inside: avoid; }
	img { max-width: 100% !important; }
	p, h2, h3 { orphans: 3; widows: 3; }
	h2, h3 { page-break-after: avoid; }
	@page { margin: 0.5cm; }

	#page-footer {
		border-top: 1px solid #000;
		height: auto !important;
	}

	.navigation {
		display: none;
	}

}