/**
 * Form Styles
 *
 * 	_Global
 * 	_Validation
 * 	_layout
 *
 */



 /*
 * ================================
 *      _Global
 * ================================
 */

	.form-control,
	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	input[type="color"],
	textarea,
	select {

		padding: $padding-base-vertical $padding-base-horizontal;
		border-radius: $input-border-radius;
		border: 2px solid $input-border;
		font-family: $font-family-base;
		background-color: $input-bg;
		font-size: $font-size-base;
		background-image: none;
		color: $input-color;
		line-height: 20px;
		display: block;
		padding: 0 5px;
		height: 35px;
		width: 100%;

		@include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
		@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
		@include placeholder;

		&::-ms-expand {
			background-color: transparent;
			border: 0;
		}

		&[disabled],
		&[readonly],
		fieldset[disabled] & {
			background-color: $input-bg-disabled;
			border-color: #d5dbdb;
			color: #d5dbdb;
			opacity: 0.7;
		}

		&[disabled],
		fieldset[disabled] & {
			cursor: not-allowed;
		}

		&:invalid {
			background: $input-bg;
		}

		&:hover{
			border-color: $input-border-active;
			outline: 0;
		}

		&:focus{
			border-color: lighten($input-border-active, 5%);
			background-color: $input-bg-active;
			outline: 0;
		}

		&.has-icon {
			padding-right: 30px;
		}

		&.small {
			max-width: 100px;
			width: 25%;
		}

		&.medium {
			max-width: 200px;
			width: 50%;
		}

		&.large {
			max-width: 300px;
			width: 75%;
		}

		&.error,
		&.has-error {
			border-color: $input-error;
			color: $input-error;
			font-weight: bold;
			cursor: auto;
		}
	}

	label {
		display: inline-block;
		margin-bottom: 5px;
		font-size: 1.05em;
		max-width: 100%;

		&.error,
		&.has-error {
			color: $input-error;
		}
	}

	fieldset {
		min-width: 0;
		padding: 0;
		margin: 0;
		border: 0;

		& + fieldset {
			margin-top: em(50px);
		}
	}

	legend {
		font-size: ($font-size-base * 1.5);
		line-height: inherit;
		display: block;
		width: 100%;
		padding: 0;
		border: 0;
	}

	form ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	form ul li {
		padding: 0;
		margin: 0;

		& + li {
			margin-top: em(18px);
		}
	}

	textarea {
		min-height: em(125px);
		resize: vertical;
		height: auto;
	}

	select {
		padding: 0 1%;
		width: 100%;
		height: 35px;
	}

	input[type="search"] {
		@include box-sizing(border-box);
		-webkit-appearance: none;
	}

	input[type="file"] {
		display: block;
	}

	input[type="submit"],
	input[type="button"],
	button {
		@extend .btn;

		&.align-right{
			margin-left: 85%;
			padding-right: 0;
			padding-left: 0;
			width: 15%;
		}
	}

	input[type="range"] {
		display: block;
		width: 100%;
	}

	select[multiple],
	select[size] {
		height: auto;
	}

	input[type="radio"],
	input[type="checkbox"] {
		line-height: normal;
		margin: 0 0 0 5px;
	}

	input[type="radio"] + label,
	input[type="checkbox"] + label {
		display: inline-block;
	}

	// chosen
	.chosen-container {
		.chosen-single {
			border: 2px solid $input-border;
			color: $input-color;
			background: $input-bg;
			box-shadow: none;
			display: block;
			font-size: 1em;
		}
	}

	// Select2
    .select2-container--default,
    .select2-container {
        .select2-selection--single .select2-selection__placeholder,
        .select2-selection__placeholder {
            color: $text-color;
        }

        .select2-selection--default,
        .select2-selection {
            border: 2px solid $input-border;
			background: $input-bg;
            padding: .135rem .2em;
			color: $input-color;
            height: auto;
        }

        .select2-selection__choice {
            border-radius: none;
        }

        .select2-results {
            margin: 5px 0 0 0;
        }

        .select2-results > .select2-results__options {
            max-height: 600px;
        }


        .select2-dropdown {
			border-width: 2px;
			border-color: $input-border;
		}

		.select2-dropdown input.select2-search__field {
            box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
            border: 2px solid $input-border;
            padding: 8px 10px;
            font-size: 1.2em;
            outline: none;
            height: 40px;

            &:hover {
                border-color: $input-border;
            }

            &:focus,
            &:active {
                border-color: $input-border;
            }
        }

        .select2-selection__choice,
        .select2-selection--multiple .select2-selection__choice {
            border: 2px solid $input-border;
            background-color: transparent;
            cursor: default;
            margin-right: 5px;
            margin-top: 5px;
            padding: 0 5px;
            float: left;
        }

        .select2-selection--single .select2-selection__arrow {
            background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 24 24'><path fill='rgb(68,68,68)' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
            background-position: 95% 55%;
			background-repeat: no-repeat;
			height: 100%;

            b {
                visibility: hidden;
            }
        }
    }