/**
 * Ecommerce Styles
 *
 */

#page-body .products {
    list-style: none;
    padding: 0;
    margin: 0;
    
    & + .products {
        border-top: 1px solid #eee;
        margin-top: 40px;
        padding-top: 40px;

        @include breakpoint(tablet) {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
    }

    .product {
        margin: 0;
        padding: 0;
        clear: both;
        @include clearfix();

        & + .product {
            border-top: 2px solid #eee;
            margin-top: 40px;
            padding-top: 40px;
        }
    
        .price {
            text-align: right;
            margin-top: 15px;
        }

        .onsale {
            text-align: right;
        }

        .container {
            padding: 0;
        }

        .img-container,
        .img-column {
            margin-bottom: 15px;
            margin: 0;
            position: relative;
            padding: 0;
            margin-bottom: 10px;
            text-align: center;

            a {
                background: #fff;
                display: block;
                border: 1px solid #ccc;
                overflow: hidden;
                height: 200px;
                max-height: 300px;

                &:hover,
                &:focus {
                    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 24px 0px;
                }
            }

            .onsale {
                position: absolute;
                top: 1%;
                left: 1%;
                background: $brand-color-2;
                padding: 5px 10px;
                color: #fff;
                font-size: 1em;
            }

            @include breakpoint(tablet) {
                margin-bottom: 0;
                width: 43%;
                margin: 0;
                padding: 0;
                float: left;

                a {
                    height: auto;
                    max-height: 300px;
                }
            }
        }

        .woocommerce-loop-product__title,
        .title {
            overflow: hidden;
            padding: 0 0 10px;
            margin: 0 0 5px 0;
            color: $brand-color-2;
            font-size: 1.4em;

            h1,h2,h3,h4,h5,h6 {
                color: $brand-color-2;
                font-size: 1em;
                margin: 0;
                padding: 0;
            }
        }

        .onsale {
            font-size: 1.3em;
            font-weight: 400;
            padding: 5px 0;
            margin: 0;
            color: $brand-color-1;
            display: block;
            margin-top: 15px;
        }

        .container {
            padding: 25px;
        }

        .meta {
            margin: 0;
            padding: 10px 0;
            font-size: .9em;

            @include breakpoint(tablet) {
                float: right;
                width: 53%;
            }
        }

        .add_cart {
            text-align: right;
            margin-top: 10px;
        }

        .add_to_cart_button {
            display: inline-block;
            text-align: center;
            margin-top: 0;
            @extend .btn;
            @extend .btn-primary;
        }

        .added_to_cart {
            @extend .btn;
            @extend .btn-info;
        }
        
        .price {
            display: block;
            font-size: 1.5em;
            margin-top: 0;
        
            del {
                font-size: .75em;
                display: inline-block;
                color: #777;
            }
    
            ins {
                background: transparent;
                display: inline-block;
                font-size: 1em;
            }
        }
    }

    @include breakpoint(tablet) {
        &.columns-2,
        &.columns-3,
        &.columns-4 {
            .product {
                clear: none;

                & + .product {
                    border-top: none;
                    margin-top: 0;
                    padding-top: 0;
                }
        
                .price {
                    text-align: left;
                    margin-top: 5px;
                }

                .img-container,
                .img-column {
                    margin-bottom: 0;
                    width: 100%;
                    float: none;
                }

                .img-container a,
                .img-column  a {
                    overflow: hidden;
                    height: 200px;
                }

                .woocommerce-loop-product__title,
                .title {
                    white-space: nowrap;
                    overflow: hidden;
                    padding: 10px 0;
                    margin: 0;
                    text-overflow: ellipsis;
                    font-size: 1em;
        
                    h1,h2,h3,h4,h5,h6 {
                        font-size: 1em;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }

        &.columns-2 {
            .product {
                float: left;
                width: 50%;
                margin-top: 0;
                padding-top: 0;
            }
        }

        &.columns-3 {
            .product {
                border: none;
                float: left;
                width: 33.3%;
                margin-top: 0;
                padding-top: 0;
            }
        }

        &.columns-4 {
            .product {
                border: none;
                float: left;
                width: 25%;
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

}