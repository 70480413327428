/**
 * Ecommerce Styles
 *
 */

#page-body .product-single.product {

    @include clearfix;
    position: relative;

    .img-column {
        position: relative;
        overflow: hidden;

        @include breakpoint(tablet) {
            float: left;
            width: 45%;
        }

        .images {
            overflow: hidden;
            text-align: left;

            figure {
                border: 1px solid #ccc;
                text-align: center;
            }
    
            img {
                max-width: auto;
                height: auto;
                width: auto;
            }
    
            a {
                border-radius: 2px;
                background: #fff;
                display: block;
            }
    
            a.last {
                margin-right: 0;
            }
    
            .thumbnails {
                display: block;
                text-align: center;
                padding-top: 20px;
                font-size: 0px;
                line-height: 0;
                margin: 0px;
        
                a {
                    display: inline-block;
                    overflow: hidden;
                    background: #fff;
                    margin: 0px 4px;
                    max-width: 60px;
                    height: 60px;
                    padding: 5px;
                    width: auto;
                    float: none;
                }

                a.zoom {
                    display: block;
                    width: 22.05%;
                    margin-right: 3.8%;
                    float: left;
                    margin-bottom: 1em;
    
                    &.last {
                        margin-right: 0;
                    }
    
                    &.first {
                        clear: both;
                    }
                }
            }
        }
    }

    .meta {
        margin-top: 40px;

        .product_meta {
            > span {
                display: block;

                & + span {
                    padding-top: 5px;
                }
            }
        }

        @include breakpoint(tablet) {
            margin-top: 0;
            padding: 25px 0;
            float: right;
            width: 50%;
        }
    }

    .content {
        clear: both;
    }

    .onsale {
        font-size: 1.3em;
        display: block;
        font-weight: 400;
        margin: 25px 0 0 0;
        color: $brand-color-1;
        text-align: right;
    }

    .title {
        font-size: 1em;
        margin: 0 0 15px 0;

        h1 {
			color: $brand-color-1;
			font-size: 1.667em;
			text-shadow: 2px 2px 1px #c8cabd;
			line-height: 32px;
			padding: 0;
			margin: 0;
			font-weight: 400;
        }
    }

    .summary {
        padding: 20px 0 0 0;
        position: relative;
        float: right;
        width: 60%;

        .link {
            margin-top: 40px;
        }

        @include breakpoint(tablet) {
            margin-top: 40px;
            float: none;
            width: 100%;
        }
    }

    .add_cart {
        margin: 5px 0 0 0;
        text-align: center;

        a, button {
            @extend .btn;
            @extend .btn-primary;
            padding: 10px 38px;
            font-size: 1.3em;

            @include breakpoint(tablet) {
                padding: .375rem 1.25rem;
                margin-top: -3px;
                font-size: 1.2em;
            }
        }

        @include breakpoint(tablet) {
            text-align: right;
        }
    }

    .price {
        display: block;
        margin: 15px 0 0 0;
        font-size: 1.3em;
        
        del {
            font-size: .85em;
            display: inline-block;
            color: #777;
        }

        ins {
            background: transparent;
            color: $brand-color-1;
            display: inline-block;
            font-size: 1em;
        }

        @include breakpoint(tablet) {
            text-align: right;
        }
    }

    .variations {
        &,th,td,tr {
            border: none;
        }
    }

    .quantity {
        margin: 0 4px 5px 0;
        display: inline-block;
        width: 60px;

        input {
            border-radius: 0;
            height: 38px;
        }

        @include breakpoint(tablet) {
            margin: 0;
        }
    }

    .woocommerce-tabs {
        padding: 40px 0 0 0;
        clear: both;

        .tabs {
            position: relative;
            padding: 0px 0px 0px 8px;
            margin: 0px;
            background: none;
            border-radius: 0px;
            list-style: none;
            font-size: .86em;
            text-align: center;

            @include breakpoint(tablet) {
                text-align: left;
                font-size: 1em;
            }
        }

        .tabs li {
            display: inline-block;
            border-bottom: none;
            background: none;
            margin: 0;
            padding: 0;
            top: 0px;

            &.active a {
                border-bottom: 1px solid #fff;
                text-decoration: none;
                background: #fff;
                cursor: default;
                display: block;
                bottom: -1px;
            }
        }

        .tabs a {
            text-shadow: none;
            color: inherit;
            text-decoration: none;
            display: block;
            border-image-source: initial;
            border-image-slice: initial;
            border-image-width: initial;
            border-image-outset: initial;
            border-image-repeat: initial;
            font-weight: normal;
            padding: 12px 15px;
            background: rgb(233, 234, 235);
            border-radius: 0px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(214, 214, 214);
            margin: 0px 0px -1px 0;
            transition: all 200ms linear;
        }

        .panel {
            box-shadow: rgba(0, 0, 0, 0.0470588) 0px 2px 3px;
            border: 1px solid rgb(214, 214, 214);
            background: #fff;
            padding: 25px;
        }

        .panel h2 {
            font-size: 1.5em;
        }
    }

    .variations {
        margin: 0;

        th,
        td {
            display: list-item;
            padding: 0;
            list-style: none;
            background-color: transparent;
        }

        .value {
            margin-bottom: 1em;
        }
    }

    .single_variation {
        .price {
            margin-bottom: 1em;
            display: block;
        }
    }

    .variations_button {
        @include clearfix;
        padding-top: 1em;
    }

    .woocommerce-product-rating {
        margin-bottom: ms(3);
        @include clearfix;
        margin-top: - ms(-1);

        a {
            text-decoration: underline;
        }

        .star-rating {
            float: left;
            margin-right: ms(-2);
        }
    }

}