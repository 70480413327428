
	@mixin icon-font() {
		-webkit-font-smoothing: antialiased;
		background-position: 0% 0%;
		background-repeat: repeat;
		font-family: FontAwesome;
		text-decoration: inherit;
		vertical-align: baseline;
		background-image: none;
		font-weight: normal;
		font-style: normal;
		display: inline;
		height: auto;
		width: auto;
	}

	@mixin icon($icon, $pos:'before') {
		&:#{$pos} {
			@include icon-font();
			content: $icon;
		}
	}
