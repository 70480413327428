/**
 * Nav Styles
 *
 */

#page-nav {
	font-size: 1em;
	padding: 0;
	margin: 0;
	display: none;
	background-color: #066a9d;
	border-top: 1px solid #0078bc;
	border-bottom: 1px solid #0078bc;

	@include breakpoint(tablet) {
		display: block;
	}

	@include clearfix();

	a {
		text-decoration: none;
		text-align: center;
		line-height: 1em;
		display: block;
		color: #ebece3;
		padding: 20px 10px;
		margin: 0;
		border-right: 1px solid #0078bc;

		@include transition(background .3s ease);

		&:hover,
		&:focus {
			background-color: #d51b00;	
		}
	}

	ul {
		list-style: none;
		display: flex;
		padding: 0;
		margin: 0;
	}

	ul > li {
		white-space: nowrap;
		position: relative;
		flex: 1 1 auto;
		padding: 0;
		margin: 0;

		&.current {
			background-color: darken(#066a9d, 5%);
		}

		&.active > h1 > a {
			background: $brand-primary;
			background: #4d4d4d;
		}

		&.active ul {
			visibility: visible;
			opacity: 1;
		}

		&:first-child > a {
			border-left: 1px solid #0078bc;
		}
	}

 	ul > li ul {
		background: lighten($brand-primary, 5%);
		visibility: hidden;
		position: absolute;
		min-width: 100%;
		display: block;
		padding: 5px 0;
		z-index: 10;
		opacity: 0;
		top: 100%;
		margin: 0;
		left: 0;

		@include transition(all .3s ease);

		&.edge {
			left: auto;
			right: 0;
		}

		ul {
			position: absolute;
			bottom: auto;
			right: auto;
			left: 100%;
			margin: 0;
			top: 0;
		}

		li {
			position: relative;
			float: none;
			padding: 0;
			margin: 0;
		}

		a {
			text-decoration: none;
			text-transform: none;
			padding: 10px 15px;
			text-align: left;
			display: block;
			border: none;
			margin: 0;
		}
	}
}

#mobile-nav {
	background-color: #066a9d;
	visibility: hidden;
	max-width: 300px;
	overflow: hidden;
	position: fixed;
	font-size: 16px;
	height: 100%;
	z-index: 500;
	right: -70%;
	width: 70%;

	@include transition(all .3s ease);

	.nav-button {
		line-height: ($header-height-mobile - 3);
		height: $header-height-mobile;
		width: $header-height-mobile;
		text-decoration: none;
		position: relative;
		margin: 10px 10px 10px 0;
		text-align: center;
		text-indent: 3px;
		font-size: 38px;
		display: block;
		float: right;
		color: #fff;

		@include transition(all .3s ease);
		@include icon($fa-var-navicon);

		@include breakpoint(tablet) {
			display: none;
		}
	}

	.search-form {
		padding: 30px;
		display: flex;

		form {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		input {
			height: 32px;
			width: auto;
		}

		input[type="text"] {
			max-width: 80%;
		}

		input[type="submit"] {
			padding: 5px 8px;
		}
	}

	.container-wrap {
		border-top: 1px solid #0078bc;
		top: $header-height-mobile + 16px;
		position: absolute;
		overflow: auto;
		width: 100%;
		bottom: 0;

		@include breakpoint(tablet) {
			top: $header-height + 16px;
		}
	}

	.primary-nav > ul > li > a {
		border-bottom: 1px solid #eee;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		position: relative;
		padding: 0;
		margin: 0;
	}

	li a {
		text-decoration: none;
		padding: 15px 25px;
		color: #fff;
		display: block;

		@include transition(all .3s ease);

		&:hover,
		&:focus {
			background-color: #d51b00;	
		}
	}

	li.dropdown.open > a.dropdown-toggle,
	li.dropdown > a.dropdown-toggle {
		padding-right: 50px;

		@include icon($fa-var-caret-down);

		&:before {
			text-align: center;
			position: absolute;
			line-height:50px;
			font-size: 22px;
			display: block;
			width: 50px;
			right: 0;
			top: 0;
		}
	}

	li.dropdown.open {
		&:hover,
		&:focus {
			background-color: #d51b00;	
		}
	}

	li.dropdown .dropdown-menu {
		background-color:darken(#066a9d, 5%);
		display: block;
	}

	ul ul,
	.dropdown-menu {
		border-bottom: 1px solid #eee;
		background: transparent;
		position: relative;
		font-size: 14px;
		display: none;
		bottom: auto;
		border: none;
		float: none;
		width: 100%;
		z-index: 0;
		top: auto;

		li {
			border: none;
		}

		li:after {
			display: none;
		}

		a {
			padding-left: 40px;
		}
	}
}

#mobile-nav-bg {
	background: rgba(255,255,255,.95);
	visibility: hidden;
	color: $text-color;
	max-width: 300px;
	overflow: hidden;
	position: fixed;
	z-index: 199;
	height: 100%;
	right: -70%;
	width: 70%;

	@include transition(all .3s ease);
	@include box-shadow(0 -2px 7px -1px rgba(0,0,0,.4));
}

body.nav-open {
	#mobile-nav-bg,
	#mobile-nav {
		visibility: visible;
		display: block;
		right: 0;
	}

	#page:before {
		visibility: visible;
		position: absolute;
		background: #000;
		height: 100%;
		content: "";
		width: 100%;
		opacity: .6;
		right: 0;
		top: 0;
	}

	#mobile-nav .nav-button,
	#page-header .nav-button {
		@include icon($fa-var-times);
	}

	@include breakpoint(tablet) {
		#mobile-nav,
		#mobile-nav-bg {
			visibility: hidden;
		}

		#page:before {
			visibility: hidden;
		}

		#page-header .nav-button {
			display: none;
		}
	}
}

