/**
 * Ecommerce Styles
 *
 */

#page-body {

    .woof_products_top_panel {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            margin: 0;
            padding: 0;
        
            & + li {
                margin-left: 10px;
            }
        }
        
        a {
            border: 1px solid #d0d0d0;
            vertical-align: middle;
            display: inline-block;
            padding: 0.25em 0.4em;
            white-space: nowrap;
            border-radius: 2px;
            color: $text-color;
            text-align: center;
            line-height: .8em;
            margin: 1px 0;
            font-size: .9em;
            cursor: pointer;
            line-height: 1;

            &:hover,
            &:focus {
                background-color: #d0d0d0;
                color: #fff;
            }
        
            span {
                background-image: none;
                padding: 0;

                &:after {
                    padding-left: 5px;
                    font-weight: bold;
                    content: 'x';
                }
            }
        }
    }

    .woof {
        h4 {
            border: none;
            font-size: .85em;
            font-weight: normal;
            margin: 0;
            padding: 0 0 5px 0;
        }

        .woof_submit_search_form_container {
            text-align: center;
            overflow: visible;

            button {
                float: none !important;
                display: inline-block;
            }
        }
    }

    .term-description {
        font-size: .9em;
        font-style: italic;
        display: none;

        p {
            margin: 0;
            padding: 0;
        }
    }

    // Results count
    .woocommerce-result-count {
        margin: 0;
        padding: 0;
        font-size: .9em;
    }

    // Filtering
    .woocommerce-ordering {
        border-bottom: 4px solid #4d4d4f;
        margin: 10px 0 20px 0;
        padding: 0 0 20px 0;

        select {
            display: inline-block;
            width: auto;
        }
    }

    // Pagination
    .woocommerce-pagination {
        text-align: center;
        padding-top: 30px;
        clear: both;

        ul {
            list-style: none;
            display: inline-block;
            white-space: nowrap;
            clear: both;
            border-image-source: initial;
            border-image-slice: initial;
            border-image-width: initial;
            border-image-outset: initial;
            border-image-repeat: initial;
            padding: 0;
            border-width: 1px 0px 1px 1px;
            border-color: rgb(224, 218, 223) rgb(224, 218, 223) rgb(224, 218, 223);
            border-style: solid solid solid;
            border-right: 0px;
            margin: 1px;
            border-radius: 5px;
            overflow: hidden;
        }

        li {
            border-right: 1px solid rgb(224, 218, 223);
            overflow: hidden;
            display: inline;
            padding: 0px;
            margin: 0px;
            float: left;
        }

        span,
        a {
            text-decoration: none;
            line-height: 1em;
            font-weight: 400;
            font-size: 1em;
            min-width: 1em;
            display: block;
            padding: .7em;
            margin: 0px;
        }

        span {
            background: rgb(247, 246, 247);
            color: rgb(153, 136, 150);
        }
    }

    // Info message
    .woocommerce-error,
    .woocommerce-message,
    .woocommerce-info {
        border-bottom :1px solid #e8e4e3;
        border-right :1px solid #e8e4e3;
        border-left :1px solid #e8e4e3;
        border-radius: 3px;
        background: #fff;
    }
    
    // Password strength
    .woocommerce-password-strength {
    	text-align: center;
    	font-weight: 600;
    	padding: 3px .5em;
    	font-size: 1em;

    	&.strong {
    		background-color: #c1e1b9;
    		border-color: #83c373;
    	}

    	&.short {
    		background-color: #f1adad;
    		border-color: #e35b5b;
    	}

    	&.bad {
    		background-color: #fbc5a9;
    		border-color: #f78b53;
    	}

    	&.good {
    		background-color: #ffe399;
    		border-color: #ffc733;
    	}
    }

    .woocommerce-password-hint {
    	margin: .5em 0 0 0;
    	display: block;
    }

    // Related products
    .related {
        margin: 45px 0 0 0;

        .content {
            display: none;
        }

        a {
            color: $text-color;
        }

        > h2 {
			color: $brand-color-1;
			text-transform: uppercase;
			font-size: 1.667em;
			text-shadow: 2px 2px 1px #c8cabd;
			line-height: 32px;
			padding: 0;
			margin: 0;
            font-weight: 400;
            text-align: center;
        }

        .products .product .add_to_cart_button {
            margin-top: 10px;
        }
    }

    // Review
	p.stars {
		a {
			position: relative;
			height: 1em;
			width: 1em;
			text-indent: -999em;
			display: inline-block;
			text-decoration: none;

			&:before {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 1em;
				height: 1em;
				line-height: 1;
				font-family: "WooCommerce";
				content: "\e021";
				text-indent: 0;
			}

			&:hover {
				~ a:before {
					content: "\e021";
				}
			}
		}

		&:hover {
			a {
				&:before {
					content: "\e020";
				}
			}
		}

		&.selected {
			a.active {
				&:before {
					content: "\e020";
				}

				~ a:before {
					content: "\e021";
				}
			}

			a:not(.active) {
				&:before {
					content: "\e020";
				}
			}
		}
    }

    // Stock
    .stock {
        &:empty::before {
            display: none;
        }

        &.in-stock {
            color: $brand-success;
        }

        &.out-of-stock {
            color: $brand-danger;
        }
    }

    // variations
    a.reset_variations {
        display: inline-block;
        margin-left: 1em;
    }
}