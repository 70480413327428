/**
 * Global Styles
 */

/*
 * ================================
 * 	_Global
 * ================================
 */

	html {
		background: $background-color;
		box-sizing: border-box;
		height: 100%;
	}

	body {
		background: $background-color;
		min-width: $screen-min;
		height: 100%;
		padding: 0;
		margin: 0;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	.inside {
		min-width: $screen-min;
		max-width: $screen-max;
		position: relative;
		margin: 0 auto;
		padding: 0;
		width: 96%;

		@include clearfix();
	}

	#page-wrap {
		min-height: 100%;
		position: relative;
	}

	#page {
		position: relative;
		min-height: 100%;
		width: 100%;

		@include breakpoint(tablet) {
			height: 100%;
		}

		&:before {
			background-position: 50% 200px;
			background-attachment: fixed;
			background-repeat: no-repeat;
			position: absolute;
			visibility: hidden;
			background: #fff;
			z-index: 100;
			height: 100%;
			width: 100%;
			content: "";
			opacity: 0;
			left: 0;
			top: 0;
			@include transition(all .3s linear);
		}
	}

	.error-content {
		padding: 100px 0;
		max-width: 500px;
		text-align: center;
		margin: 0 auto;

		h1 {
			font-size: 2.4em;
		}
	}

/*
 * ================================
 * 	_Other
 * ================================
 */

	// Helper Classes
	.group { @include clearfix(); }
	.visible { display: block; }
	.hidden { display: none; }

	// Selection Color
	::-moz-selection {background: $brand-color-2; color: #fff; text-shadow: -1px 1px 0 $text-color-dark;}
	::selection {background: $brand-color-2; color: #fff; text-shadow: -1px 1px 0 $text-color-dark;}
	a::-moz-selection {background: $link-color; color: #fff;}
	a::selection {background: $link-color; color: #fff;}
	img::-moz-selection {background: transparent;}
	img::selection {background: transparent;}
