/**
 * Ecommerce Styles
 *
 */

#page-body .woocommerce {

	#customer_details {
		margin: 0 0 50px 0;

		.col2-set {
			@include clearfix();
		}

		.col-2 {
			background-color: darken($background-color, 5%);
			padding: 25px;
		}
	}

	.woocommerce-billing-fields {
		border: 1px solid $brand-border;

		h3 {
			background: $brand-border;
			border: none;
			text-transform: capitalize;
			font-size: .9em;
			padding: 10px;
			font-weight: 700;
		}

		.woocommerce-billing-fields__field-wrapper {
			padding: 10px 20px;
		}
	}

	.form-row {
		padding: 3px;
		margin: 0 0 15px;
	}

	#order_review_heading {
		@extend .page-title;
		clear: both;
	}

	#order_review .shop_table {
		border-top: 2px solid $brand-color-4;
		margin: 25px 0 50px 0;
		padding: 10px 0 0 0;
		border-radius: 0;

		td {
			background: #fff;
		}

		tfoot {
			th {
				text-align: right;
			}
		}
	}

}