/**
 * Footer Styles
 */

#page-footer {

/*
 * ================================
 * 	_Global
 * ================================
 */

	background: $footer-bg;
	color: $text-color;
	position: relative;
	overflow: hidden;
	font-size: 100%;

/*
 * ================================
 * 	_FooterTop
 * ================================
 */

 	#footer-top {
		padding: 40px 0;
		border-top: 4px solid #4d4d4f;
		overflow: hidden;

		.heading {
			padding-bottom: 20px;
			font-size: 20px;
			color: #d51b00;
			font-weight: 400;
			
			@include breakpoint(tablet) {
				text-transform: uppercase;
				letter-spacing: .2em;
			}
		}

		.subheading {
			padding: 20px 0;
			font-size: 1.333em;
			color: #3c3c3c;
		}

		p {
			line-height: 1.4;
			margin-bottom: 1.38889em;
		}

		.col {
			& + .col {
				margin-top: 40px;
			}

			@include breakpoint(tablet) {
				width: 70%;
				float: left;

				& + .col {
					margin: 0;
					width: 30%;
				}
			}
		}

		.box {
			clear: both;
			position: relative;

			& + .box {
				padding-top: 30px;
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				padding-right: 0px;
				overflow: hidden;

				ul {
					display: none;
				}

				li {
					margin: 0;
					padding: 0;
					font-size: .667em;
					list-style: none;
				}

				a {
					color: #4d4d4f;
					text-decoration: none;
					text-transform: uppercase;
					letter-spacing: .04em;
					display: block;
					padding: 2px 0;
					font-size: 1.1em;

					&:focus,
					&:hover {
						text-decoration: underline;
					}
				}
			}

			@include breakpoint(tablet) {
				ul.split {
					max-width: 40%;

					li {
						width: 50%;
						float: left;
					}
				}
			}
		}

		.verified-image {
			position: absolute;
			top: 0;
			right: 0;
		}

		.search-form {
			input {
				display: inline-block;
				vertical-align: middle;
				height: 32px;
				width: auto;
			}
	
			input[type="submit"] {
				padding: 5px 8px;
			}
		}
 	}

/*
 * ================================
 * 	_FooterBar
 * ================================
 */

	#footer-bar {
		background: #353535;
		overflow: hidden;
		font-size: .8em;
		padding: 15px 0;
		font-size: 12px;

		.copyright {
			float: left;
			color: #fff;
			padding: 10px 0 0 0;

			p {
				line-height: 1;
				font-size: 1em;
				display: inline-block;
				margin: 0;
			}

			a {
				color: #fff;
				text-decoration: underline;
			}

			ul {
				padding: 0 0 0 5px;
				overflow: hidden;
				list-style: none;
				display: none;
				line-height: 1;
				font-size: .95em;
				margin: 0;

				@include breakpoint(tablet) {
					display: inline-block;
				}
			}

			li {
				display: inline-block;
				padding: 0;
				margin: 0;
			}

			li + li {
				margin-left: 5px;
			}

			li:before {
				content: "|";
				padding-right: 5px;
			}
		}

		.branding {
			float: right;
			overflow: hidden;

			a {
				background: url("#{$image-path}/logo-okaydesign.png") no-repeat top right;
				text-transform: lowercase;
				text-decoration: none;
				white-space: nowrap;
				text-indent: -999em;
				line-height: 33px;
				text-align: left;
				color: #59C3D9;
				display: block;
				height: 33px;
				float: right;
				width: 45px;
				clear: both;
				padding: 0;

				&:hover span {
					color: #d51b00;
				}

				@include breakpoint(tablet) {
					padding: 0 45px 0 0;
					line-height: 33px;
					text-align: right;
					text-indent: 0;
					height: 33px;
					width: auto;
				}
			}

			span {
				text-decoration: underline;
				@include transition(color .3s ease);
			}
		}
	}

}
